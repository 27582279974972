import * as Rails from 'rails-ujs';

export default class Terminals {
  constructor() {
    this.search = $('#terminal-search');
    this.searchToggle = $('#terminal-search-toggle');

    this.search.find('input[type="checkbox"], input[type="radio"], select').on('change', () => {
      Rails.fire(this.search[0], 'submit');
    });

    this.searchToggle.on('click', () => {
      if ($(window).width() < 960) {
        this.search.stop().slideToggle(400);
        this.searchToggle.toggleClass('open');
      }
    });

    $(window).on('load resize', () => {
      const windowWidth = $(window).width();

      if (windowWidth >= 960) {
        this.searchToggle.attr('tabindex', -1);
      } else {
        this.searchToggle.removeAttr('tabIndex');
      }
    });
  }
}