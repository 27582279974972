import Cookies from 'js-cookie';

export default class Alerts {
  constructor() {
    this.alertCookie = Cookies.get('port_alert_dismiss');
    this.alert = $('.alert--global');
    this.close = $('.alert__close');
    this.sessionData = $('.alert--global').data('session');
    this.link = $('.alert--global a.link');

    $('.alert__toggle button').on('click', (e) => {
      const target = $(e.currentTarget || e.target);
      this.toggleContent(target);
    });

    this.close.on('click', () => {
      this.closeAlert();
    });

    this.link.on('click', () => {
      this.redirect();
    });

    if (this.alertCookie && (this.alertCookie !== this.sessionData)) {
      this.showAlert(true);
    } else if (!this.alertCookie) {
      this.showAlert();
    }
  }

  toggleContent(target) {
    target.toggleClass('open');

    const parent = target.parents('.alert__header');
    parent.next().stop().slideToggle(400);
  }

  closeAlert() {
    Cookies.set('port_alert_dismiss', this.sessionData, { expires: 1 });

    const alertHeight = this.alert.height();
    this.alert.animate({ 'margin-top': -alertHeight }, 300, () => {
      this.alert.removeClass('alert--global-display');
    });
  }

  showAlert(removeCookie) {
    if (removeCookie) {
      Cookies.remove('port_alert_dismiss');
    }

    this.alert.addClass('alert--global-display')
  }

  redirect() {
    Cookies.set('port_alert_dismiss', this.sessionData, { expires: 1 });
  }
}
