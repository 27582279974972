export default class Menu {
  constructor() {
    this.menuButton = $('.menu-toggle');
    this.childrenButton = $('.children-toggle');
    this.menu = $('.header-nav');

    this.menuButton.on('click', () => {
      this.toggleMenu();
    });

    this.childrenButton.on('click', (e) => {
      this.toggleChildren($(e.currentTarget || e.target));
    });
  }

  toggleMenu() {
    this.menuButton.toggleClass('open');

    this.menu
      .stop()
      .slideToggle(400);
  }

  toggleChildren(target) {
    target.toggleClass('open');
    target
      .next()
      .stop()
      .slideToggle(400);
  }
}
