import 'core-js/stable';
import 'regenerator-runtime/runtime';
import objectFitImages from 'object-fit-images';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Alerts from './alerts';
import Anchors from './anchors';
import Events from './events';
import Gallery from './gallery';
import Marinas from './marinas';
import Menu from './menu';
import Search from './search';
import Sliders from './sliders';
import Terminals from './terminals';
import Video from './video';

new Alerts();
new Anchors();
new Events();
new Gallery();
new Marinas();
new Menu();
new Search();
new Sliders();
new Terminals();
new Video();

objectFitImages();
