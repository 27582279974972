import * as moment from 'moment';
import 'fullcalendar';

export default class Events {
  constructor() {
    this.calendar = $('#calendar');
    this.singleDay = $('#single-day');

    this.selectedDate;

    this.initCalendar();
  }

  initCalendar() {
    this.calendar.fullCalendar({
      events: '/events.json',
      header: {
        left: '',
        center: 'prev,title,next',
        right: ''
      },
      dayNamesShort: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
      height: 'auto',
      fixedWeekCount: false,
      showNonCurrentDates: false,
      eventAfterRender: (e, element) => {
        if (element.parent().parent().index() === 0) {
          const index = element.parent().index();
          element.closest('.fc-week').find('.fc-content-skeleton thead td').eq(index).addClass('fc-has-event');
        }

        $('.fc-icon-left-single-arrow').attr('aria-label', 'previous month');
        $('.fc-prev-button').attr('aria-label', 'previous month');
        $('.fc-icon-right-single-arrow').attr('aria-label', 'next month');
        $('.fc-next-button').attr('aria-label', 'next month');
      },
      eventAfterAllRender: (view) => {
        $('.fc-state-highlight').children('.fc-day-number').addClass('active');

        if (!this.selectedDate) {
          $('.fc-today').find('.fc-day-number').addClass('active');
        } else {
          const dateString = moment(this.selectedDate).format('YYYY-MM-DD');
          $('.fc-day-top[data-date="' + dateString + '"]').find('.fc-day-number').addClass('active');
        }

        if (!this.selectedDate) {
          this.selectedDate = this.calendar.fullCalendar('getDate');
          this.singleDay.next().fadeIn(100);
        }

        $('.fc-day-number').on('click', (e) => {
          this.calendar.find('.fc-day-number').removeClass('active');
          $(e.currentTarget || e.target).addClass('active');
        });

        $.ajax({
          url: `/events/day_events.js?category=${this.category}`,
          data: {
            day: this.selectedDate.format()
          },
          success: () => {
            this.singleDay.next().fadeOut(300);
          }
        });

        this.calendar.next().fadeOut(300);
      },
      dayClick: (date) => {
        this.selectedDate = date;
        this.singleDay.next().fadeIn(100);

        $.ajax({
          url: `/events/day_events.js?category=${this.category}&day=${date.format()}`,
          success: () => {
            this.singleDay.next().fadeOut(300);
          }
        });
      }
    });

    $('.fc-prev-button, .fc-next-button').on('click', (e) => {
      this.calendar.next().fadeIn(100);
    });
  }
}
