import Vimeo from '@vimeo/player';

export default class Video {
  constructor() {
    if ($('.video').length > 0) {
      let tag = document.createElement('script');
      tag.src = "https://www.youtube.com/iframe_api";
      let firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      window.onYouTubeIframeAPIReady = () => {
        $('.video').each((index, video) => {
          const $video = $(video);

          if ($video.find('video').length > 0) {
            $video.find('.video__play, .video__cover').on('click', () => {
              this.playVideo($video);
            });
          } else if ($video.find('iframe').attr('src').includes("youtube")) {
            $video.find('.video__play, .video__cover').on('click', () => {
              this.hideCover($video);

              new YT.Player($video.find('iframe').attr('id'), {
                events: {
                  'onReady': onPlayerReady
                }
              });
            });
          } else {
            $video.find('.video__play, .video__cover').on('click', () => {
              this.hideCover($video);

              const vimeoVideo = new Vimeo($video.find('iframe').attr('id'));
              vimeoVideo.play();
            });
          }
        });
      };

      window.onPlayerReady = (event) => {
        event.target.playVideo();
      }
    }
  }

  hideCover(video) {
    const cover = video.find('.video__cover'),
      play = video.find('.video__play');

    play.fadeOut(400);
    cover.fadeOut(400);
  }

  playVideo(video) {
    this.hideCover(video);
    const file = video.find('video');

    file[0].play();
  }
}
