export default class Search {
  constructor() {
    this.overlay = $('.search-overlay');
    this.open = $('#search-toggle');
    this.close = $('.search-overlay__close');

    this.open.on('click', () => {
      this.overlay.fadeIn(400);
    });

    this.close.on('click', () => {
      this.overlay.fadeOut(400);
    });
  }
}