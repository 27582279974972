export default class Gallery {
  constructor() {
    $('.gallery').each((index, gallery) => {
      const $gallery = $(gallery),
        $inner = $gallery.find('.gallery__inner');

      const $captionContainer = $gallery.find('.gallery__captions'),
        $captions = $captionContainer.find('.caption');

      $inner.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
        const $nextCaption = $captions.eq(nextSlide);
        $captions.removeClass('active');
        $nextCaption.addClass('active');

        const newHeight = $nextCaption.height();
        $captionContainer.height(newHeight);
      });

      $inner.slick({
        arrows: true,
        dots: true
      });
    });
  }
}