import * as Rails from 'rails-ujs';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

export default class Marinas {
  constructor() {
    if ($('#map').length > 0) {
      this.form = $('#marina-toggles');

      this.form.find('input').on('change', (e) => {
        Rails.fire(this.form[0], 'submit');
      });

      this.map = L.map('map', {
        crs: L.CRS.Simple,
        maxZoom: 2,
        minZoom: 1,
        maxBoundsViscosity: 0.6,
        scrollWheelZoom: false,
        tap: false
      });

      const bounds = [[0, 0], [810, 598]];
      L.imageOverlay('/assets/marina_boat-launches_map.svg', bounds).addTo(this.map);

      this.map.fitBounds(bounds);
      this.map.setMaxBounds(bounds);

      this.map.setZoom(1);

      this.addMarkers();

      $(document).on('click', '.map-marker', (e) => {
        const target = $(e.currentTarget),
          targetId = target.data('target');

        const cardOffset = $(`#${targetId}`).offset().top - 20;

        $('html, body').animate({ scrollTop: cardOffset }, 1000);
      });
    }
  }

  addMarkers(filters) {
    $.ajax({
      url: '/marinas.json',
      method: 'GET',
      success: (markers) => {
        markers.forEach((marker) => {
          const markerHtml = `
            <div class="map-marker${marker.item_type === 'Boat Launch' ? ' map-marker--green' : ''}" data-target="card-${marker.id}">
              <div class="map-marker__inner${marker.item_type === 'Boat Launch' ? ' map-marker__inner--green' : ''}">
                ${marker.position + 1}
              </div>
            </div>
          `;

          const newMarker = L.divIcon({ className: 'map-marker-wrap', html: markerHtml });
          L.marker([810 - marker.y_position, marker.x_position], { icon: newMarker }).addTo(this.map);
        });
      }
    });
  }
}
